import {BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {
  ApiModule,
  BASE_PATH, Configuration, ConfigurationParameters
} from '@dip-gw/dip-gw-portal-ts-angular';
import {environment} from '../environments/environment';
import {SharedModule} from './layout/shared/shared.module';
import {NgxUiLoaderConfig, NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderService} from 'ngx-ui-loader';
import { HttpClientModule } from '@angular/common/http';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {registerLocaleData} from '@angular/common';
import localeTh from '@angular/common/locales/th';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#FBAF34',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'chasing-dots',
  blur: 5,
  delay: 0,
  fgsColor: '#FBAF34',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'chasing-dots',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: '#FBAF34',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 500
};


let sharedApiModuleConfig: Configuration;

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
  };

  params.apiKeys = {
    Authorization: ''
  };

  if (sharedApiModuleConfig == null) {
    sharedApiModuleConfig = new Configuration(params);
  }
  return sharedApiModuleConfig;
}

registerLocaleData(localeTh);

// @ts-ignore
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    ApiModule.forRoot(apiConfigFactory),
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true,
      excludeRegexp: [] }),
  ],
  providers: [
    NgxUiLoaderService,
    {
      provide: BASE_PATH, useValue: environment.apiBasePath
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
